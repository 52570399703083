import { IconButton } from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import { getLinkToken, exchangePublicToken } from "../data/plaiddata";
import {
  usePlaidLink,
  // PlaidLinkOptions,
  // PlaidLinkOnSuccess,
} from "react-plaid-link";

import { useState, useEffect } from "react";

const PlaidLink = () => {
  const [token, setToken] = useState(null);
  useEffect(() => {
    const createLinkToken = async () => {
      getLinkToken().then((linkToken) => {
        setToken(linkToken.link_token);
        // console.log(token);
        // console.log("token:", linkToken.link_token);
        // console.log("response:", linkToken);
      });
    };
    createLinkToken();
  }, []);

  const onSuccess = (public_token, metadata) => {
    exchangePublicToken(public_token).then((data) => {
      console.log(data, metadata);
      // console.log("exchange Public Token: ", data);
      // console.log("metadata: ", metadata);
    });
  };
  const onExit = (err, metadata) => {
    console.log("OnExit err: ", err, " MetaData: ", metadata);
  };
  const onEvent = (eventName, metadata) => {
    console.log("onEvent eventName: ", eventName, " MetaData: ", metadata);
  };

  // const { open, exit, submit, ready } = usePlaidLink({
  //   token,
  //   onSuccess,
  //   onEvent,
  //   onExit,
  // });
  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onEvent,
    onExit,
  });

  return (
    <IconButton onClick={open} disabled={!ready}>
      <AddCardIcon />
    </IconButton>
  );
};

export default PlaidLink;
