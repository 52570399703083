import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
// import { mockTransactions } from "../../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "../../../components/LineChart";
import BarChart from "../../../components/BarChart";
import GeographyChart from "../../../components/GeographyChart";
import StatBox from "../../../components/StatBox";
import ProgressCircle from "../../../components/ProgressCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PaidIcon from "@mui/icons-material/Paid";

import {
  getBalance,
  getTransactions,
  getTransactionList,
  getStaticMetrics,
} from "../../../data/plaiddata";
import { useEffect, useState } from "react";

import { Amplify } from "aws-amplify";
import awsExports from "../../../aws-exports";

// import { getCurrentUser } from "aws-amplify/auth";
// import { fetchAuthSession } from "aws-amplify/auth";

Amplify.configure(awsExports);

// async function getCurrentCognitoUser() {
//   const { username, userId, signInDetails } = await getCurrentUser();
//   const auths = await fetchAuthSession();
//   // console.log("session", auths);

//   // console.log("username", username);
//   // console.log("user id", userId);
//   // console.log("sign-in details", signInDetails);
// }

function round_2(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

const Dashboard = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const [balance, setBalance] = useState("");
  const [transaction_line, setTransaction_line] = useState(false);
  const [transaction_list, setTransaction_list] = useState(false);
  const [staticMetrics, setStaticMetrics] = useState(null);
  const [monthDifference, setMonthDifference] = useState(null);

  // useEffect(() => {
  //   getCurrentCognitoUser();
  // }, []);

  useEffect(() => {
    getBalance().then((result) => {
      let balance = 0;
      for (const account_type in result) {
        for (const data in result[account_type]) {
          balance += result[account_type][data]["balance"]["current_balance"];
        }
      }
      // console.log(balance);
      if (result === undefined) setBalance(null);
      else setBalance(balance);
    });
  }, []);

  useEffect(() => {
    getTransactions("both").then((result) => {
      // console.log(result);
      if (
        result === undefined ||
        (result.outflow.length === 0 && result.inflow.length === 0)
      ) {
        setTransaction_line(null);
      } else setTransaction_line(result.outflow);
    });
  }, []);

  useEffect(() => {
    getTransactionList().then((result) => {
      console.log("getTransactionList", result);
      if (result === undefined) setTransaction_list(null);
      else setTransaction_list(result.data);
    });
  }, []);

  useEffect(() => {
    getStaticMetrics().then((result) => {
      if (result === undefined) setStaticMetrics(null);
      else setStaticMetrics(result);
    });
  }, []);

  useEffect(() => {
    if (staticMetrics !== null && balance !== "") {
      setMonthDifference({
        difference:
          Math.abs(staticMetrics.months_income) -
          Math.abs(staticMetrics.months_expenses),
        balance: balance,
      });
    }
  }, [staticMetrics, balance]);

  return (
    <Box m="20px" paddingBottom={"20px"}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="WELCOME TO YOUR DASHBOARD" />

        <Box>
          <Button
            sx={{
              backgroundColor: colours.blueAccent[700],
              color: colours.gray[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>
      {/* Grid and charts */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* Row 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colours.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {balance && (
            <StatBox
              title={`$${round_2(balance)}`}
              subtitle="Balance"
              icon={
                <AccountBalanceIcon
                  sc={{ color: colours.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          )}
          {/* <StatBox
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            icon={
              <EmailIcon
                sc={{ color: colours.greenAccent[600], fontSize: "26px" }}
              />
            }
          /> */}
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colours.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {staticMetrics && (
            <StatBox
              title={`$${round_2(staticMetrics.months_income)}`}
              subtitle="Previous Month Total Income"
              icon={
                <AddIcon
                  sc={{ color: colours.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          )}
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colours.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {staticMetrics && (
            <StatBox
              title={`$${round_2(staticMetrics.months_expenses)}`}
              subtitle="Previous Month Total Expenses"
              icon={
                <RemoveIcon
                  sc={{ color: colours.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          )}
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colours.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {monthDifference && (
            <StatBox
              title={round_2(monthDifference.difference)}
              subtitle="Difference for the Past Month"
              progress={round_2(
                Math.abs(monthDifference.difference / monthDifference.balance)
              )}
              increase={round_2(
                Math.abs(monthDifference.difference / monthDifference.balance) *
                  100
              )}
              _inc={monthDifference.difference > 0}
              icon={
                <PaidIcon
                  sc={{ color: colours.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          )}
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colours.primary[400]}
        >
          <Box
            mt="0px"
            p="10px 20px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colours.gray[100]}
              >
                Expenses
              </Typography>
              {/* <Typography
                variant="h3"
                fontWeight="bold"
                color={colours.greenAccent[500]}
              >
                $59,301,332
              </Typography> */}
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colours.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="300px" m="-50px 0 0 0">
            {transaction_line && (
              <LineChart isDashboard={true} data={transaction_line} />
            )}
          </Box>
        </Box>
        {/* Transcations */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colours.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colours.primary[500]}`}
            color={colours.gray[100]}
            p="15px"
          >
            <Typography color={colours.gray[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {transaction_list &&
            transaction_list.toReversed().map((transaction, i) => {
              return (
                <Box
                  key={`${transaction.transaction_id}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colours.primary[500]}`}
                  p="15px"
                >
                  <Box>
                    <Typography
                      color={
                        transaction.amount >= 0
                          ? colours.redAccent[500]
                          : colours.greenAccent[500]
                      }
                      variant="h5"
                      fontWeight="600"
                    >
                      {transaction.name}
                    </Typography>
                    <Typography color={colours.gray[100]}>
                      {transaction.personal_finance_category
                        .confidence_level === "HIGH"
                        ? transaction.personal_finance_category.primary
                            .replace("_", " ")
                            .toLowerCase()
                        : transaction.category === null
                        ? ""
                        : transaction.category.at(-1)}
                    </Typography>
                  </Box>
                  <Box color={colours.gray[100]}>{transaction.date}</Box>
                  <Box
                    backgroundColor={
                      transaction.amount >= 0
                        ? colours.redAccent[500]
                        : colours.greenAccent[500]
                    }
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    {`$${round_2(
                      transaction.amount < 0
                        ? transaction.amount * -1
                        : transaction.amount
                    )}`}
                  </Box>
                </Box>
              );
            })}
        </Box>

        {/* ROW 3 */}
        {/*<Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colours.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colours.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colours.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ p: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colours.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600" sx={{ mb: "15px" }}>
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>*/}

        {/*  */}
      </Box>
    </Box>
  );
};

export default Dashboard;
