import { useTheme } from "@emotion/react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { getCredentials } from "../../../data/fetchCognito";
import { useEffect, useState } from "react";
// import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
// import PasswordIcon from "@mui/icons-material/Password";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import EditPfp from "../components/editPfp";
import { getUser, updateUser } from "../../../data/userControl";

import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { AccountSettings, ThemeProvider } from "@aws-amplify/ui-react";
// import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

function changePasswordSuccess() {
  console.log("change password");
  alert("Success");
  window.location.reload();
}

const Info = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const [signInDetails, setSignInDetails] = useState(null);
  const [imageHover, setImageHover] = useState(false);

  const [pfpOpen, setPFPOpen] = useState(false);
  const handleClose = () => setPFPOpen(false);
  function handleOpen() {
    setPFPOpen(true);
  }

  const [changePwordOpen, setChangePwordOpen] = useState(false);
  const handleChangePwordClose = () => setChangePwordOpen(false);
  function handleChangePwordOpen() {
    setChangePwordOpen(true);
  }

  const [userDetails, setUserDetails] = useState(null);

  const [userPfp, setUserPfp] = useState(null);
  const [username, setUsername] = useState(null);
  const [changeUname, setChangeUname] = useState(null);

  function updateUsername() {
    if (changeUname === "") {
      alert("username cannot be blank");
      setChangeUname(username);
      return;
    }
    updateUser(changeUname, null)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getCredentials().then(({ signInDetails }) => {
      setSignInDetails(signInDetails);
    });
  }, []);

  useEffect(() => {
    getUser().then((data) => {
      setUserDetails(data.data);
    });
  }, []);

  useEffect(() => {
    if (userDetails === null) {
      setUserPfp("../../assets/user.png");
      return;
    }
    if (userDetails.logo_url === null) {
      setUserPfp("../../assets/user.png");
    } else {
      setUserPfp(userDetails.logo_url);
    }
    if (userDetails.username === null) {
      getCredentials().then(({ signInDetails }) => {
        const re = /.*(?=@)/;
        const uname = re.exec(signInDetails.loginId)[0];
        setUsername(uname);
        setChangeUname(uname);
      });
    } else {
      setUsername(userDetails.username);
      setChangeUname(userDetails.username);
    }
  }, [userDetails]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        padding="10px 10px 10px 10px"
      >
        <div
          style={{
            borderRadius: "50%",
            backgroundColor: "black",
            width: "100px",
            height: "100px",
            position: "relative",
          }}
        >
          <ModeEditOutlinedIcon
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate3d(-50%,-50%,0)",
              opacity: `${imageHover ? "100%" : "0%"}`,
              // alignItems: "center",
              // justifyContent: "center",
            }}
          />
          <img
            alt="profile-user"
            width="100px"
            height="100px"
            src={`${userPfp}`}
            onMouseEnter={() => setImageHover(true)}
            onMouseLeave={() => setImageHover(false)}
            onClick={handleOpen}
            style={{
              cursor: "pointer",
              borderRadius: "50%",
              opacity: `${imageHover ? "50%" : "100%"}`,
            }}
          />
        </div>
        <Box marginLeft="30px">
          {/* <Box justifyContent="left" display="flex"> */}
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            {username && (
              <TextField
                id="input-username"
                label="change username"
                variant="standard"
                defaultValue={`${username}`}
                onChange={(event) => {
                  setChangeUname(event.target.value);
                }}
              />
            )}
            {username !== changeUname && (
              <Box position="relative" alignContent="space-evenly">
                <IconButton
                  onClick={() => updateUsername()}
                  sx={{ float: "left" }}
                >
                  <CheckRoundedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          {/* </Box> */}
          <Box justifyContent="left" display="flex">
            <EmailIcon />
            <Typography
              variant="h5"
              color={colours.gray[100]}
              // fontWeight={"bold"}
              sx={{ m: "5px" }}
            >
              {signInDetails ? signInDetails.loginId : ""}
            </Typography>
          </Box>

          <Box justifyContent="left" display="flex">
            {/* <PasswordIcon /> */}

            <Typography
              variant="h5"
              color={colours.gray[100]}
              // fontWeight={"bold"}
              sx={{ m: "5px" }}
            >
              Change Password
            </Typography>

            <IconButton onClick={handleChangePwordOpen}>
              <ModeEditOutlinedIcon />
            </IconButton>
            <Modal
              open={pfpOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: `${colours.primary[400]}`,
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <EditPfp />
              </Box>
            </Modal>

            <Modal
              open={changePwordOpen}
              onClose={handleChangePwordClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: `${colours.primary[400]}`,
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <ThemeProvider
                  theme={{
                    name: "custom-theme",
                    tokens: {
                      colors: {
                        border: {
                          primary: { value: `${colours.primary[100]}` },
                          secondary: { value: `${colours.primary[200]}` },
                          tertiary: { value: `${colours.primary[300]}` },
                        },
                        font: {
                          primary: { value: `${colours.primary[100]}` },
                          secondary: { value: `${colours.primary[200]}` },
                          tertiary: { value: `${colours.primary[300]}` },
                        },
                      },
                      radii: {
                        small: { value: "2px" },
                        medium: { value: "3px" },
                        large: { value: "4px" },
                        xl: { value: "6px" },
                      },
                    },
                  }}
                >
                  <AccountSettings.ChangePassword
                    onSuccess={changePasswordSuccess}
                  />
                </ThemeProvider>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Info;
