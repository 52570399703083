import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Collapse,
  // ListItemButton,
  useTheme,
  Modal,
  Stack,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { get_bank_accounts, remove_item } from "../../../data/plaiddata";
import { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMore from "@mui/icons-material/ExpandMore";

import ExpandLess from "@mui/icons-material/ExpandLess";

const AccountSettings = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [deleteAccountID, setDeleteAccountID] = useState(null);

  const handleConfirmOpen = (persistent_account_id, ins_name) => {
    setDeleteAccountID({
      item_id: persistent_account_id,
      name: ins_name,
    });
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => setConfirmOpen(false);

  const [accounts, setAccounts] = useState([]);
  const [numAccounts, setNumAccounts] = useState(0);

  function deleteAccount(item_id) {
    remove_item(item_id).then(() => handleConfirmClose());
    window.location.reload();
  }

  useEffect(() => {
    get_bank_accounts().then((data) => {
      if (data === undefined) {
        setAccounts([]);
        setNumAccounts(0);
      } else {
        setAccounts(data);
        setNumAccounts(data.length);
      }
    });
  }, []);
  return (
    <Box>
      <Typography
        variant="h3"
        color={colours.greenAccent[500]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        Bank Accounts
      </Typography>

      <Typography variant="h5" color={colours.gray[100]} sx={{ mb: "5px" }}>
        Connected Accounts: {numAccounts}
      </Typography>
      <List dense={false}>
        {accounts &&
          accounts.map((account, i) => {
            return (
              <Box>
                <ListItem key={account.item_id}>
                  <ListItemText primary={account.institution_name} />
                  <IconButton onClick={handleClick}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      handleConfirmOpen(
                        account.item_id,
                        account.institution_name
                      )
                    }
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </ListItem>
                <Collapse in={open}>
                  <List>
                    {account.account.map((account) => {
                      return (
                        <ListItem
                          key={account.persistent_account_id}
                          sx={{ pl: 4 }}
                        >
                          <ListItemText primary={account.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </Box>
            );
          })}
      </List>
      {deleteAccountID && (
        <Modal
          open={confirmOpen}
          onClose={handleConfirmClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: `${colours.primary[400]}`,
              border: `2px solid ${colours.primary[600]}`,
              boxShadow: 24,
              p: 2,
              borderRadius: "5px",
            }}
          >
            <Stack spacing={2}>
              <Typography>
                Are you sure you want to remove the connection with{" "}
                {deleteAccountID.name}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => deleteAccount(deleteAccountID.item_id)}
                >
                  <CheckRoundedIcon />
                </IconButton>
                <IconButton onClick={handleConfirmClose}>
                  <ClearRoundedIcon />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default AccountSettings;
