import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

async function getCredentials() {
  Amplify.configure(awsExports);
  const { username, userId, signInDetails } = await getCurrentUser();
  const { credentials, identityId, tokens, userSub } = await fetchAuthSession();
  //   {
  //     "tokens": {
  //         "accessToken": {
  //             "payload": <Object>
  //         },
  //         "idToken": {
  //             "payload": <Object>
  //         },
  //         "signInDetails": {
  //             "loginId": Email string,
  //             "authFlowType": String
  //         }
  //     },
  //     "credentials": {
  //         "accessKeyId": String,
  //         "secretAccessKey": String,
  //         "sessionToken": String,
  //         "expiration": "2024-08-17T19:39:59.000Z"
  //     },
  //     "identityId": String,
  //     "userSub": String (unique UserId),
  //     "username": <String>,
  //     "userId": String,
  //     "loginId": Email String,
  //     "authFlowType": String,
  // }

  return {
    username,
    userId,
    signInDetails,
    credentials,
    identityId,
    tokens,
    userSub,
  };
}

export { getCredentials };
