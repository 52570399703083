import { Routes, Route } from "react-router-dom";

import Topbar from "./global/Topbar";
import Dashboard from "./dashboard";
import Sidebar from "./global/Sidebar";
import Team from "./team";
import Transactions from "./transactions";
import Contacts from "./contacts";
import Bar from "./bar";
import Form from "./form";
import Line from "./line";
import Pie from "./pie";
import FAQ from "./faq";
import Geography from "./geography";
import Calendar from "./calendar";

// import { Amplify } from "aws-amplify";
// import awsExports from "../../aws-exports";

// import { getCurrentUser } from "aws-amplify/auth";
// // import { fetchAuthSession } from "aws-amplify/auth";

// Amplify.configure(awsExports);

// const session = await fetchAuthSession();

// console.log("session", session);

// const currentuser = await getCurrentUser();
// console.log(currentuser);

const Home = () => {
  return (
    <div className="app">
      <Sidebar />

      <main className="content">
        <Topbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/form" element={<Form />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/bar" element={<Bar />} />
          <Route path="/pie" element={<Pie />} />
          <Route path="/line" element={<Line />} />
          <Route path="/faq" element={<FAQ />} />

          <Route path="/geography" element={<Geography />} />
        </Routes>
      </main>
    </div>
  );
};

export default Home;
