import { Box, Typography, useTheme } from "@mui/material";
// import { Box, Toolbar, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
// import { mockDataInvoices } from "../../../data/mockData";
import Header from "../../../components/Header";
import { useEffect, useState } from "react";
import { getTransactionList } from "../../../data/plaiddata";

const Transactions = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  const columns = [
    // { field: "transaction_id", headerName: "id" },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "merchant_name", headerName: "Store", flex: 1 },
    { field: "iso_currency_code", headerName: "Currency", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={
            params.row.amount >= 0
              ? colours.redAccent[500]
              : colours.greenAccent[500]
          }
        >
          ${Math.abs(params.row.amount)}
        </Typography>
      ),
    },
    { field: "date", headerName: "Date", flex: 1 },
    {
      field: "personal_finance_category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => (
        <Typography>{params.row.personal_finance_category.primary}</Typography>
      ),
    },
  ];

  const [transactionList, setTransactionList] = useState(null);

  useEffect(() => {
    getTransactionList().then((result) => {
      if (result === undefined) {
        setTransactionList(null);
      } else setTransactionList(result.data);
    });
  }, []);

  return (
    <Box m="20px">
      <Header title="Transactions" subtitle="List of all Transactions"></Header>
      {transactionList === null && (
        <Typography variant="h5">No Transactions Yet...</Typography>
      )}
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colours.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colours.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colours.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colours.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colours.gray[100]} !important`,
          },
          "$ .MuiCheckbos-root": {
            color: `${colours.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={transactionList}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          getRowId={(row) => {
            return row.transaction_id;
          }}
          ignoreValueFormatterDuringExport={true}
        />
      </Box>
    </Box>
  );
};

export default Transactions;
