// import { useState } from "react";
// import { Box, Icon, IconButton, Typography, useTheme } from "@mui/material";
import { Box, Typography, useTheme } from "@mui/material";
// import Link from "@mui/material/Link";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant="h2"
        color={colours.gray[100]}
        fontWeight={"bold"}
        sx={{ mb: "5px" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colours.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
