// import { Amplify } from "aws-amplify";
// import awsExports from "../aws-exports";
// import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { getCredentials } from "./fetchCognito";

const urlRoot = process.env.REACT_APP_BACKEND_DJANGO_URL;

async function getBalance() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/get_personal_balances";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function getTransactions(_type = "both") {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/get_expenses_time_series";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
      forNivo: 0,
      type: _type,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function getTransactionList() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/gettransactions";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function getStaticMetrics() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/get_static_metrics";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function getLinkToken() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/getlinktoken";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function exchangePublicToken(public_token) {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/exchangelinktoken";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
      public_token: public_token,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function get_bank_accounts() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/get_accounts";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function remove_item(itemId) {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/plaid/remove_item";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      user_id: userSub,
      item_id: itemId,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

export {
  getBalance,
  getTransactions,
  getTransactionList,
  getStaticMetrics,
  getLinkToken,
  exchangePublicToken,
  get_bank_accounts,
  remove_item,
};
