import React, { Component } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Box, IconButton, Stack } from "@mui/material";
import { updateUser } from "../../../data/userControl";

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open("get", url);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var fr = new FileReader();

    fr.onload = function () {
      callback(this.result);
    };

    fr.readAsDataURL(xhr.response); // async call
  };

  xhr.send();
}

class EditPfp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
    };

    // if we are using arrow function binding is not required
    //  this.onImageChange = this.onImageChange.bind(this);
  }

  onImageChange = (event) => {
    // if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    toDataURL(URL.createObjectURL(img), (dataURL) => {
      this.setState({ image: dataURL });
    });
    document.getElementById("confirm").hidden = true;
    //   this.setState({
    //     image: URL.createObjectURL(img),
    //   });
    // }
  };

  accept = () => {
    if (this.state.image !== null)
      updateUser(null, this.state.image)
        .then(() => {
          document.getElementById("confirm").innerHTML = "Updated!";
          document.getElementById("confirm").hidden = false;
          window.location.reload();
        })
        .catch((error) => {
          document.getElementById("confirm").innerHTML = error;
          document.getElementById("confirm").hidden = false;
        });
  };

  cancel = () => {
    this.setState({
      image: null,
    });
    document.getElementById("confirm").hidden = true;
  };

  // theme = useTheme();
  // colours = tokens(this.theme.palatte.mode);

  render() {
    return (
      <div>
        <style>
          {`
          input[type="file"] {
            display: none;
            }

            .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            }
        `}
        </style>
        <div>
          <div style={{ position: "relative", alignContent: "space-evenly" }}>
            <h1>Select Profile Picture</h1>
            <div style={{ float: "left", padding: "10px", marginTop: "30px" }}>
              <label for="file-upload" class="custom-file-upload">
                Upload Image
                <input
                  id="file-upload"
                  type="file"
                  name="myImage"
                  onChange={this.onImageChange}
                />
              </label>
              <div id="confirm" hidden />
            </div>
            {this.state.image && (
              <Stack spacing={2}>
                <img
                  src={this.state.image}
                  width="100px"
                  height="100px"
                  alt=""
                />
                <Box>
                  <IconButton onClick={this.accept}>
                    <CheckRoundedIcon />
                  </IconButton>
                  <IconButton onClick={this.cancel}>
                    <ClearRoundedIcon />
                  </IconButton>
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default EditPfp;
