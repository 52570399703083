/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:47566443-e929-4c91-bf8e-eb0b4376f6a1",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_T05dPG3FG",
  aws_user_pools_web_client_id: "6bn71emv8vj1marnvuqjv6ffvv",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
