import { getCredentials } from "./fetchCognito";

const urlRoot = process.env.REACT_APP_BACKEND_DJANGO_URL;

async function addUserDjango() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/createuser";
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify({
      id: userSub,
    }),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

async function updateUser(uname, pfp) {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/updateuser";
  let body = { id: userSub };
  if (uname !== null) body = { ...body, username: uname };
  if (pfp !== null) body = { ...body, logo: pfp };
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify(body),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
    throw error.message;
  }
}

async function getUser() {
  const { tokens, userSub } = await getCredentials();
  const url = urlRoot + "api/getuser";
  const body = { id: userSub };
  const payload = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      Authorization: tokens?.accessToken.toString(),
    }),
    body: JSON.stringify(body),
  };
  try {
    const response = await fetch(url, payload);
    if (!response.ok) {
      console.log(response);
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
  }
}

export { addUserDjango, updateUser, getUser };
